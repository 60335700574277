<template>
  <b-sidebar
    id="invoice-bank"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Pay Invoice #{{ invoiceNumber }} Through Bank</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer
        ref="invoiceBankFormValidator"
        #default="{ invalid }"
      >
        <b-form
          class="p-2"
          @submit.prevent="invoiceBank"
          ref="invoiceBankForm"
        >
          <table class="table">
            <tbody>
              <tr>
                <td>Account Name</td>
                <th>Petroline East Africa Ltd</th>
              </tr>
              <tr>
                <td>Account (KSH)</td>
                <th>01136369258600</th>
              </tr>
              <tr>
                <td>Bank Name</td>
                <th>Co-operative Bank of Kenya</th>
              </tr>
              <tr>
                <td>Branch</td>
                <th>Nanyuki</th>
              </tr>
              <tr>
                <td>Swift Code</td>
                <th>KCOOKENA</th>
              </tr>
            </tbody>
          </table>


          <!-- last name -->
          <b-form-group
            label-for="amount"
            label="Amount in Kenya Shillings (KSH)"
          >
            <validation-provider
              #default="{ errors }"
              name="Amount"
              vid="amount"
              rules="required|integer|min:3"
            >
              <b-form-input
                id="amount"
                v-model.number="amount"
                type="number"
                name="amount"
                placeholder="XXXX"
              />
              <small class="text-mute d-block">Minimum transaction amount is KSH 100</small>
              <small class="text-danger d-block">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Payment Date & Time"
            label-for="paymentDate"
            key="paymentDate"
          >
            <validation-provider
              #default="{ errors }"
              name="payment date & time"
              vid="paymentDate"
              rules="required"
            >
              <flat-pickr
                v-model="paymentDate"
                id="paymentDate"
                inputId="paymentDate"
                class="form-control"
                placeholder="Select Payment Date & Time"
                :config="{
                  enableTime: true,
                  altInput: true,
                  altFormat: 'D, M d, Y h:m K',
                  dateFormat: 'Z',
                  clickOpens:true,
                }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>


          <b-form-group
            label-for="slip"
            label="Bank Slip"
          >
            <validation-provider
              #default="{ errors }"
              name="bank slip"
              vid="slip"
              rules="required"
            >
              <b-form-file
                v-model="slip"
                id="slip"
                input-id="slip"
                placeholder="Choose an image file or drop it here..."
                drop-placeholder="Drop file here..."
                accept="image/*"
              />
              <small class="text-muted d-block">Only accepts image file</small>
              <small class="text-danger d-block">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>


          <!-- Form Actions -->
          <div class="d-flex align-items-center justify-content-between">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid || formIsLoading"
              :class="{ disabled: invalid }"
            >
              <b-spinner small v-if="formIsLoading" />
              &nbsp; Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, min } from "@validations";

import {
  BSidebar,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FlatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BSpinner,
    FlatPickr,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: ["id", "invoiceNumber", "dueAmount"],
  emits: ["invoice-refresh"],
  
  data() {
    return {
      amount: null,
      paymentDate: null,
      slip: null,
      formIsLoading:false,
      required,
      integer,
      min,
    };
  },
  methods: {
    invoiceBank() {
      this.formIsLoading = true;
      this.$refs.invoiceBankFormValidator.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          formData.append('amount', this.amount);
          formData.append('created_at', this.paymentDate);
          formData.append('invoice_id', this.id);
          formData.append('invoice_number', this.invoiceNumber);
          formData.append('bank_slip', this.slip);
      
          this.$http.post('/invoices/bank',formData,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            })
            .then((response) => {
              this.formIsLoading = false;
              this.$emit('invoice-refresh');
              this.$root.$emit('bv::toggle::collapse', 'invoice-bank');
              this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: response.data.data.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });

              this.$nextTick(() => {
                this.$refs.invoiceBankFormValidator.reset();
              });
              this.$refs.invoiceBankForm.reset();
            })
            .catch((error) => {
              for (let err of error.response.data.error) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: err,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              }
              this.formIsLoading = false;
            });
        }
      });
    },
   
  },
  mounted(){
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if(collapseId == "invoice-bank" && isJustShown){
        if(this.dueAmount && this.dueAmount != null && this.dueAmount != undefined){
          this.amount = this.dueAmount;
        }
      }
    })
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>