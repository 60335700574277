var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"invoice-bank","sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"\n        d-flex\n        justify-content-between\n        align-items-center\n        content-sidebar-header\n        px-2\n        py-1\n      "},[_c('h5',{staticClass:"mb-0"},[_vm._v("Pay Invoice #"+_vm._s(_vm.invoiceNumber)+" Through Bank")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"invoiceBankFormValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"invoiceBankForm",staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.invoiceBank.apply(null, arguments)}}},[_c('table',{staticClass:"table"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Account Name")]),_c('th',[_vm._v("Petroline East Africa Ltd")])]),_c('tr',[_c('td',[_vm._v("Account (KSH)")]),_c('th',[_vm._v("01136369258600")])]),_c('tr',[_c('td',[_vm._v("Bank Name")]),_c('th',[_vm._v("Co-operative Bank of Kenya")])]),_c('tr',[_c('td',[_vm._v("Branch")]),_c('th',[_vm._v("Nanyuki")])]),_c('tr',[_c('td',[_vm._v("Swift Code")]),_c('th',[_vm._v("KCOOKENA")])])])]),_c('b-form-group',{attrs:{"label-for":"amount","label":"Amount in Kenya Shillings (KSH)"}},[_c('validation-provider',{attrs:{"name":"Amount","vid":"amount","rules":"required|integer|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"amount","type":"number","name":"amount","placeholder":"XXXX"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=_vm._n($$v)},expression:"amount"}}),_c('small',{staticClass:"text-mute d-block"},[_vm._v("Minimum transaction amount is KSH 100")]),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{key:"paymentDate",attrs:{"label":"Payment Date & Time","label-for":"paymentDate"}},[_c('validation-provider',{attrs:{"name":"payment date & time","vid":"paymentDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"paymentDate","inputId":"paymentDate","placeholder":"Select Payment Date & Time","config":{
                enableTime: true,
                altInput: true,
                altFormat: 'D, M d, Y h:m K',
                dateFormat: 'Z',
                clickOpens:true,
              }},model:{value:(_vm.paymentDate),callback:function ($$v) {_vm.paymentDate=$$v},expression:"paymentDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"slip","label":"Bank Slip"}},[_c('validation-provider',{attrs:{"name":"bank slip","vid":"slip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"slip","input-id":"slip","placeholder":"Choose an image file or drop it here...","drop-placeholder":"Drop file here...","accept":"image/*"},model:{value:(_vm.slip),callback:function ($$v) {_vm.slip=$$v},expression:"slip"}}),_c('small',{staticClass:"text-muted d-block"},[_vm._v("Only accepts image file")]),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",class:{ disabled: invalid },attrs:{"variant":"primary","type":"submit","disabled":invalid || _vm.formIsLoading}},[(_vm.formIsLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v("   Submit ")],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }