<template>
  <div>
    <skeleton-table v-if="initialize" :rows="10" :columns="fields.length" :buttons="1" :dateSearch="true" />

    <no-records-found
      v-if="showNotFound"
      text="No invoices found in your billing records"
    />

    <b-card v-else no-body class="action-buttons" :class="{'d-none': mainCardInvisiblity}">
      <b-row class="mb-1 mx-1">
        <b-col md="3">
          <div class="inline-spacing">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-50 mt-2"
              @click="downloadTransactions"
              :disabled="downloadIsLoading"
              :class="{ disabled: downloadIsLoading }"
            >
              <b-spinner small v-if="downloadIsLoading" />
              &nbsp;
              <feather-icon icon="DownloadIcon" class="mr-50" />
              <span class="align-middle">Download</span>
            </b-button>
          </div>
        </b-col>

        <b-col md="9" class="mt-2">
          <div class="d-md-flex align-items-center justify-content-end">

            <!-- basic -->
            <b-form-group  style="flex: 1 1 0" class="ml-md-1">
                <v-select
                  v-model="status"
                  :options="statuses"
                  :reduce="(singleStatus) => singleStatus.value"
                  label="text"
                  placeholder="Status"
                />
            </b-form-group>
            

            <!-- basic -->
            <b-form-group  style="flex: 1 1 0" class="ml-md-1">
                <v-select
                  v-model="invoiceNumber"
                  :options="invoiceNumbers"
                  label="text"
                  placeholder="Invoice #"
                />
            </b-form-group>

            <!-- basic -->
            <b-form-group  style="flex: 1 1 0" class="ml-md-1">
                <b-form-datepicker
                  v-model="dateFrom"
                  placeholder="Date from"
                  reset-button
                  class="rounded"
                  locale="en"
                />
            </b-form-group>

          

            <b-form-group style="flex: 1 1 0" class="ml-md-1">
              <b-input-group>
                <b-form-datepicker
                    v-model="dateTo"
                    placeholder="Date to"
                    reset-button
                    class="rounded"
                    locale="en"
                  />
                <b-input-group-append>
                  <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon rounded-circle ml-1"
                  @click="invoicesRefresh"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="text-right">
            <small v-if="dateError" class="text-danger">Both of these fields are required for date range</small>
          </div>
        </b-col>
      </b-row>

      <b-table
        show-empty
        empty-text="No records found from your search"
        empty-filtered-text="No records found from your search"
        hover
        striped
        :busy.sync="isBusy"
        :current-page="currentPage"
        :per-page="perPage"
        responsive
        :items="invoices"
        :fields="fields"
        ref="invoicesTable"
        class="pb-5"
      >
        
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        
        <template #cell(invoice_transactions_paid)="data">
          <span v-if="data.item.invoice_transactions_paid <= 0">
            -
          </span>
          <span v-else>
            KSH {{ data.item.invoice_transactions_paid }}
          </span>
        </template>
        
        <template #cell(amount_due)="row">
          <span v-if="row.item.invoice_transactions_paid >= row.item.amount">
            -
          </span>
          <span v-else>
            KSH {{row.item.amount - row.item.invoice_transactions_paid}}
          </span>
        </template>
        
        <template #cell(amount)="data"> KSH {{ data.item.amount }} </template>
        
        <template #cell(status)="data">
          <b-button
            v-if="data.item.status == 'partial'"
            variant="outline-warning"
            class="text-capitalize"
            pill
            size="sm"
          >
            Partially Paid
          </b-button>

          <b-button
            v-else-if="data.item.status == 'paid'"
            variant="outline-success"
            class="text-capitalize"
            pill
            size="sm"
          >
            Paid
          </b-button>

          <b-button
            v-else-if="data.item.status == 'unpaid'"
            variant="outline-danger"
            class="text-capitalize"
            pill
            size="sm"
          >
            Unpaid
          </b-button>

          <b-button
            v-else
            variant="outline-primary"
            class="text-capitalize"
            pill
            size="sm"
          >
            {{ data.item.status }}
          </b-button>
        </template>

        <template #cell(actions)="row">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>

            <b-dropdown-item
              v-if="row.item.status == 'partial' || row.item.status== 'unpaid'"
              @click="mpesaInstantlyPayment(row.item.ID, row.item.invoice_number, row.item.invoice_transactions_paid, row.item.amount)"
            >
              <feather-icon icon="DollarSignIcon" class="mr-50" />
              <span>Pay Instantly (M-Pesa)</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="row.item.status == 'partial' || row.item.status== 'unpaid'"
              @click="mpesaPaybillPayment(row.item.ID, row.item.invoice_number, row.item.invoice_transactions_paid, row.item.amount)"
            >
              <feather-icon icon="DollarSignIcon" class="mr-50" />
              <span>Pay Through M-Pesa Paybill</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="row.item.status == 'partial' || row.item.status== 'unpaid'"
              @click="bankPayment(row.item.ID, row.item.invoice_number, row.item.invoice_transactions_paid, row.item.amount)"
            >
              <feather-icon icon="DollarSignIcon" class="mr-50" />
              <span>Pay Through Bank</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="viewInvoice(row.item.ID, row.item.invoice_number)"
            >
              <feather-icon icon="EyeIcon" class="mr-50" />
              <span>View Invoice</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        v-if="!noRecordsFound"
      ></b-pagination>
    </b-card>

    <pay-invoice-mpesa-instantly :id="selectedId" :invoiceNumber="selectedInvoiceNumber" :dueAmount="selectedDueAmount" @invoice-refresh="invoicesRefresh" />
    <invoice-mpesa-paybill :id="selectedId" :invoiceNumber="selectedInvoiceNumber" :dueAmount="selectedDueAmount" @invoice-refresh="invoicesRefresh" />
    <invoice-bank :id="selectedId" :invoiceNumber="selectedInvoiceNumber" :dueAmount="selectedDueAmount" @invoice-refresh="invoicesRefresh" />
    <view-invoice :id="selectedId" :invoiceNumber="selectedInvoiceNumber" />
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BPagination,
  BTable,
  BButton,
  BSpinner,
  BCard,
  BFormDatepicker,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BRow,
} from "bootstrap-vue";
import NoRecordsFound from "@/views/pages/no-records-found/NoRecordsFound.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import PayInvoiceMpesaInstantly from './components/PayInvoiceMpesaInstantly.vue';
import InvoiceMpesaPaybill from './components/InvoiceMpesaPaybill.vue';
import InvoiceBank from './components/InvoiceBank.vue';
import ViewInvoice from './components/ViewInvoice.vue';
import SkeletonTable from "@/views/pages/shimmers/SkeletonTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BDropdown,
    BDropdownItem,
    BTable,
    BPagination,
    BCard,
    BButton,
    BSpinner,
    BFormDatepicker,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
    NoRecordsFound,
    SkeletonTable,
    vSelect,
    PayInvoiceMpesaInstantly,
    InvoiceMpesaPaybill,
    InvoiceBank,
    ViewInvoice,
  },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "invoice_number",
          label: "INVOICE #",
        },
        {
          key: "status",
          label: "STATUS",
        },
        {
          key: "invoice_transactions_paid",
          label: "AMOUNT PAID",
        },
        {
          key: "amount_due",
          label: "AMOUNT DUE",
        },
        {
          key: "amount",
          label: "TOTAL AMOUNT",
        },
        {
          key: "format_created_at",
          label: "DUE DATE",
        },
        {
          key: "actions",
          label: "ACTIONS",
        },
      ],
      selectedId: false,
      selectedInvoiceNumber: false,
      selectedDueAmount: false,
      status: null,
      statuses: [
        {
          text: "Paid",
          value: "paid",
        },
        {
          text: "Unpaid",
          value: "unpaid",
        },
        {
          text: "Partially Paid",
          value: "partial",
        },
        {
          text: "Pending Verification",
          value: "pending verification",
        }
      ],
      invoiceNumber: null,
      invoiceNumbers: [],
      dateFrom: null,
      dateTo: null,
      dateError: false,
      downloadIsLoading: false,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      initialize: true,
      showNotFound: false,
      noRecordsFound: false,
      mainCardInvisiblity:true,
    };
  },
  methods: {
    invoices(ctx, callback) {
      let params ="?page=" + ctx.currentPage;
      if (this.dateFrom && this.dateTo) {
        params += "&date_from=" + this.dateFrom + "&date_to=" + this.dateTo;
      }
      if (this.invoiceNumber) {
        params += "&invoice_number=" + this.invoiceNumber;
      }
      if (this.status) {
        params += "&status=" + this.status;
      }
      this.$http
        .get("/invoices/" + params)
        .then((response) => {
          this.totalRows = response.data.total_pages * 10;
          // Pluck the array of items off our axios response
          const items = response.data.data;
          if (items.length == 0) {
            this.noRecordsFound = true;

            if (this.initialize) {
              this.showNotFound = true;
            }
          } else {
            this.noRecordsFound = false;
          }
          this.initialize = false;
          this.isBusy = false;
          this.mainCardInvisiblity = false;
          callback(items);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          if (this.initialize) {
            this.showNotFound = true;
          }
          this.noRecordsFound = true;
          this.initialize = false;
          this.mainCardInvisiblity = false;
          callback([]);
          this.isBusy = false;
        });
      return null;
    },
    downloadTransactions() {
      if ((!this.dateFrom && this.dateTo) || (this.dateFrom && !this.dateTo)) {
        this.dateError = true;
        return;
      }
      let params ="";
      if (this.dateFrom && this.dateTo) {
        params += "?date_from=" + this.dateFrom + "&date_to=" + this.dateTo;
      }
      if (this.invoiceNumber && this.dateFrom && this.dateTo) {
        params += "&invoice_number=" + this.invoiceNumber;
      }
      if (this.invoiceNumber && (!this.dateFrom || !this.dateTo)) {
        params += "?invoice_number=" + this.invoiceNumber;
      }
      if (this.status && ((this.dateFrom && this.dateTo) || this.invoiceNumber) ) {
        params += "&status=" + this.status;
      }
      if (this.status && ((!this.dateFrom || !this.dateTo) && !this.invoiceNumber) ) {
        params += "?status=" + this.status;
      }
      this.downloadIsLoading = true;
      this.$http
        .get("/invoices/download" + params, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "invoices.xlsx");
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Invoices Downloaded",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.downloadIsLoading = false;
            this.dateError = false;
          }, 1000);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          this.downloadIsLoading = false;
          this.dateError = false;
        });
    },
    invoicesRefresh() {
      this.selectedId = false;
      this.selectedInvoiceNumber = false;
      this.selectedDueAmount = false;
      if ((!this.dateFrom && this.dateTo) || (this.dateFrom && !this.dateTo)) {
        this.dateError = true;
      } else {
        this.dateError = false;
        this.$refs.invoicesTable.refresh();
      }
    },
    mpesaInstantlyPayment(id, invoiceNumber, amountPaid, totalAmount){
      this.selectedId = id;
      this.selectedInvoiceNumber = invoiceNumber;

      if(amountPaid >= totalAmount){
        this.selectedDueAmount = 0;
      } else {
        let amountDue = totalAmount - amountPaid
        if(amountDue < 100) {
          this.selectedDueAmount = 100;
        } else {
          this.selectedDueAmount = amountDue;
        }
      }

      this.$root.$emit('bv::toggle::collapse', 'pay-invoice-mpesa');
    },
    mpesaPaybillPayment(id, invoiceNumber, amountPaid, totalAmount){
      this.selectedId = id;
      this.selectedInvoiceNumber = invoiceNumber;
      
      if(amountPaid >= totalAmount){
        this.selectedDueAmount = 0;
      } else {
        let amountDue = totalAmount - amountPaid
        if(amountDue < 100) {
          this.selectedDueAmount = 100;
        } else {
          this.selectedDueAmount = amountDue;
        }
      }

      this.$root.$emit('bv::toggle::collapse', 'invoice-mpesa-paybill');
    },
    bankPayment(id, invoiceNumber, amountPaid, totalAmount){
      this.selectedId = id;
      this.selectedInvoiceNumber = invoiceNumber;

      if(amountPaid >= totalAmount){
        this.selectedDueAmount = 0;
      } else {
        let amountDue = totalAmount - amountPaid
        if(amountDue < 100) {
          this.selectedDueAmount = 100;
        } else {
          this.selectedDueAmount = amountDue;
        }
      }

      this.$root.$emit('bv::toggle::collapse', 'invoice-bank');
    },
    viewInvoice(id, invoiceNumber){
      this.selectedId = id;
      this.selectedInvoiceNumber = invoiceNumber;

      setTimeout(() => {
        this.$bvModal.show("view-invoice");
      }, 100);
    }
  },
  created() {
    this.$http.get("/invoices/numbers").then((response) => {
      for (const numbers of response.data.data) {
        this.invoiceNumbers.push(numbers.invoice_number);
      }
    });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
