<template>
  <b-sidebar
    id="invoice-mpesa-paybill"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Pay Invoice #{{ invoiceNumber }} Through M-Pesa Paybill</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer
        ref="invoiceMpesaPaybillFormValidator"
        #default="{ invalid }"
      >
        <b-form
          class="p-2"
          @submit.prevent="invoiceMpesaPaybill"
          ref="invoiceMpesaPaybillForm"
        >
          <ol class="pl-1 mb-2" style="line-height:1.9;">
            <li>Go to the M-Pesa menu</li>
            <li>Select Payment services</li>
            <li>Select Pay Bill</li>
            <li>Enter <strong>4036793</strong></li>
            <li>Enter <strong>{{user.phone}}</strong> as the account number.</li>
            <li>Enter amount in KSH.</li>
            <li>Enter M-PESA PIN</li>
            <li>You will receive notification from M-PESA with a confirmation code</li>
            <li>Well done! Your account will be topped-up automatically</li>
          </ol>

          <!-- phone number -->
          <b-form-group label="Enter M-Pesa Phone Number" label-for="phone">
            <validation-provider
              #default="{ errors }"
              name="Phone"
              vid="phone"
              rules="required|integer"
            >
              <b-form-input
                id="phone"
                type="tel"
                v-model="phone"
                placeholder="1234 567 8900"
              />
              <small class="text-danger d-block">{{ errors[0] }}</small>
              <small class="text-danger d-block" v-if="invalidPhone">Invalid Phone Number</small>
            </validation-provider>
          </b-form-group>

          <!-- last name -->
          <b-form-group
            label-for="amount"
            label="Amount in Kenya Shillings (KSH)"
          >
            <validation-provider
              #default="{ errors }"
              name="Amount"
              vid="amount"
              rules="required|integer|min:3"
            >
              <b-form-input
                id="amount"
                v-model.number="amount"
                type="number"
                name="amount"
                placeholder="XXXX"
              />
              <small class="text-mute d-block">Minimum transaction amount is KSH 100</small>
              <small class="text-danger d-block">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="code"
            label="Transaction Code"
          >
            <validation-provider
              #default="{ errors }"
              name="transaction code"
              vid="code"
              rules="required"
            >
              <b-form-input
                id="code"
                v-model="code"
                name="code"
                placeholder="XXXX"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Payment Date & Time"
            label-for="paymentDate"
            key="paymentDate"
          >
            <validation-provider
              #default="{ errors }"
              name="payment date & time"
              vid="paymentDate"
              rules="required"
            >
              <flat-pickr
                v-model="paymentDate"
                id="paymentDate"
                inputId="paymentDate"
                class="form-control"
                placeholder="Select Payment Date & Time"
                :config="{
                  enableTime: true,
                  altInput: true,
                  altFormat: 'D, M d, Y h:m K',
                  dateFormat: 'Z',
                  clickOpens:true,
                }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>


          <!-- Form Actions -->
          <div class="d-flex align-items-center justify-content-between">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid || formIsLoading || invalidPhone"
              :class="{ disabled: invalid }"
            >
              <b-spinner small v-if="formIsLoading" />
              &nbsp; Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, min } from "@validations";

import {
  BSidebar,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BCard,
  BCardText,
  BCardTitle,
  BProgress,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FlatPickr from "vue-flatpickr-component";
import { getUserData } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    BCardTitle,
    BProgress,
    FlatPickr,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: ["id", "invoiceNumber", "dueAmount"],
  emits: ["invoice-refresh"],
  computed:{
    invalidPhone(){
      if(this.phone == "" || this.phone == null){
        return false;
      }
      let phoneSanitize = this.phone
      phoneSanitize = phoneSanitize.replaceAll(" ", '')
      if(phoneSanitize.substr(0, 1) == "+"){
        phoneSanitize = phoneSanitize.substr(1)
      }
      if(phoneSanitize.substr(0, 2) == "00"){
        phoneSanitize = phoneSanitize.substr(2)
      }
      if(phoneSanitize.substr(0, 1) == "0"){
        phoneSanitize = phoneSanitize.substr(1)
      }
      if(phoneSanitize.substr(0, 3) == "254"){
        phoneSanitize = phoneSanitize.substr(3)
      }

      if (phoneSanitize.length == 9) {
        return false;
      } else {
        return true;
      }
    }
  },
  data() {
    return {
      phone: null,
      amount: null,
      code: null,
      paymentDate: null,
      user: null,
      formIsLoading:false,
      required,
      integer,
      min,
    };
  },
  methods: {
    invoiceMpesaPaybill() {
      this.formIsLoading = true;
      this.$refs.invoiceMpesaPaybillFormValidator.validate().then((success) => {
        if (success) {
          this.$http
            .post("/invoices/mpesa-payback", {
              phone: this.phone,
              amount: this.amount,
              invoice_id: this.id,
              invoice_number: this.invoiceNumber,
              receipt_number: this.code,
              created_at: this.paymentDate,
            })
            .then((response) => {
              this.formIsLoading = false;
              this.$emit('invoice-refresh');
              this.$root.$emit('bv::toggle::collapse', 'invoice-mpesa-paybill');
              this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: response.data.data.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });

              this.$nextTick(() => {
                this.$refs.invoicePayMpesaFormValidator.reset();
              });
              this.$refs.invoicePayMpesaForm.reset();
            })
            .catch((error) => {
              for (let err of error.response.data.error) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: err,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              }
              this.formIsLoading = false;
            });
        }
      });
    },
   
  },
  created() {
    this.user = getUserData();
  },
  mounted(){
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if(collapseId == "invoice-mpesa-paybill" && isJustShown){
        if(this.dueAmount && this.dueAmount != null && this.dueAmount != undefined){
          this.amount = this.dueAmount;
        }
      }
    })
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>